body {
  background-color: rgb(0, 0, 0);
}
.vinylbanner img {
  width: 100%;
  filter: blur(4px);
  object-fit: contain;
  display: block;
}

.vinylbanner {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.vinylbanner p {
  top: 3em;
  position: absolute;
  background-color: #fff0f0;
  font-size: 3em;
  color: #000000;
  font-family: Georgia, "Times New Roman", Times, serif;
}

h1.vinyltap {
  text-align: center;
  font-size: 2.5em;
  font-family: Georgia, "Times New Roman", Times, serif;
}

h2.vinyltap {
  margin-top: 2em;
  font-size: 2em;
  text-align: center;
  font-family: Georgia, "Times New Roman", Times, serif;
}

.vinylsitemap-container img {
  display: flex;
  justify-content: center;
  margin-left: 3em;
  width: 95%;
}

h3.vinyltap {
  font-weight: lighter;
  font-family: "Courier New";
  margin-left: 15em;
  margin-right: 15em;
  font-size: 1em;
  line-height: 1.5em;
}

h4.vinyltap {
  font-weight: lighter;
  font-family: "Courier New";
  margin-right: 6em;
  margin-left: 8em;
  font-size: 1em;
  margin-top: 2em;
  line-height: 1.5em;
}

ul.vinyllist {
  font-family: "Courier New", Courier, monospace;
  font-size: 1em;
  margin-left: 20em;
  margin-top: 2em;
  line-height: 1.5em;
}

.vinylmockuppic {
  justify-content: center;
  display: flex;
  align-items: center;
}

.vinylmockuppic img {
  align-self: center;
  width: 40%;
}

.vinylmockuppic2 {
  justify-content: center;
  display: flex;
  align-items: center;
}

.vinylmockuppic2 img {
  align-self: center;
  width: 70%;
  margin-top: 1.5em;
}
