#about {
  background: rgb(249, 230, 199);
  height: 90vh;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;
}

.aboutpic {
  max-width: 30%;
  width: 24em; /* Set the width of the image */
  height: 36em; /* Maintain aspect ratio */
  margin-left: 9rem;
  margin-top: 7rem;
  border-top-left-radius: 10rem;
  border-top-right-radius: 10rem;
  filter: brightness(105%);
  filter: contrast(85%);
}

.about-headers {
  z-index: 1000;
  margin-top: 2.8vw;
  position: absolute;
  margin-left: 35%;
  font-size: 400%;
  font-family: "syncopate", sans-serif;
  color: rgb(236, 98, 0);
  font-weight: lighter;
}

.about-text {
  color: rgb(236, 98, 0);
  margin-left: 12%;
  font-size: 130%;
  margin-right: 50%;
  line-height: 1.6em;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.about-text:first-of-type {
  margin-bottom: 2%; /* Reduced space after the first paragraph */
  margin-top: 14vw;
}

@media (max-width: 864px) {
  #about {
    flex-direction: column;
    /* Adjust based on your layout */
    /* Adjust height based on content */
  }

  .aboutpic {
    max-width: 100%;
    width: 10em;
    height: 14em; /* Adjust image width for mobile */
    margin-top: 16%; /* Adjust top margin */
  }

  .about-headers {
    margin-top: 0.8em; /* Adjust top margin for header */
    font-size: 7vw; /* Responsive font size */
    margin-left: 1em; /* Center the header */
  }

  .about-text {
    margin: 1.2em; /* Adjust text margins for readability */
    font-size: 1rem; /* Responsive font size */
  }
  .about-text p {
    line-height: 1.5em;
  }

  .about-text:first-of-type {
    margin-bottom: 5px; /* Reduced space after the first paragraph */
    margin-top: 2em;
  }
}

@media (max-width: 500px) {
  #about {
    height: 100vh;
  }

  .about-text {
    color: rgb(236, 98, 0);
    font-size: 90%;
    line-height: 1.2em;
    font-family: "Courier New", Courier, monospace;
  }
}

.bold1 {
  color: rgb(50, 143, 143);
  font-weight: bold;
}

.bold2 {
  color: rgb(55, 126, 14);
  font-weight: bold;
}

.bold3 {
  color: rgb(119, 7, 82);

  font-weight: bold;
}
