body {
  background-color: white;
}
.calendarbanner img {
  width: 100%;
  filter: blur(2px);
  object-fit: contain;
  display: block;
}

.calendarbanner {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.calendarbanner p {
  top: 3em;
  position: absolute;
  background-color: #ffffff;
  font-size: 3.5em;
  color: #000000;
  font-family: Georgia, "Times New Roman", Times, serif;
}

h1.calendar {
  text-align: center;
  font-size: 2.5em;
  font-family: Georgia, "Times New Roman", Times, serif;
}

h2.calendar {
  margin-top: 1.5em;
  font-size: 2em;
  text-align: center;
  font-family: Georgia, "Times New Roman", Times, serif;
}

.pacesitemap-container {
  display: flex;
  justify-content: center;
  margin-top: 0;
}

.pacesitemap-container img {
  display: flex;
  justify-content: center;

  width: 75%;
}

h3.calendar {
  font-weight: lighter;
  font-family: "Courier New";
  margin-left: 12em;
  margin-right: 12em;
  font-size: 1em;
  line-height: 1.5em;
}

h4.calendar {
  font-weight: lighter;
  font-family: "Courier New";
  margin-right: 8em;
  margin-left: 12em;
  font-size: 1em;
  margin-top: 2em;
  line-height: 1.5em;
}

.pace-mockup {
  display: grid;
  grid-template-columns: 5fr 5fr;
  margin-top: 3em;
  margin-left: 20%;
  margin-right: 20%;
}

.pace-mockup img {
  width: 90%;
  filter: contrast(80%);
}

.pacediagram {
  display: flex;
  justify-content: center;
  align-items: center;
}

.pacemockup-grid {
  display: grid;
  grid-template-columns: 35vw 35vw;
  max-width: 100%;
  margin-left: 20em;
  margin-right: 20em;
}

.pacemockup-grid img {
  width: 80%;
  object-fit: contain;
  margin-bottom: 0;
  justify-content: center;
  align-items: center;
  align-content: center;
  align-self: center;
}

.pacemockup-text p {
  display: inline;
  text-align: left;
  font-size: 1.8em;
  margin-bottom: 0;
  font-weight: bold;
  font-family: Georgia, "Times New Roman", Times, serif;
  margin-left: 1em;
  color: rgb(47, 61, 128);
  background-color: rgb(199, 255, 126);
}

.pacemockup-text {
  flex-grow: 1;
  margin: 0 20px;
}

.pacearrow {
  font-size: 2em; /* Larger arrow, adjust size as needed */
  padding: 0 20px; /* Optional: Adds padding to the arrow for better visibility */
}
