body {
  background-color: white;
}

.rwcbanner img {
  width: 100%;
  filter: blur(2px);
  object-fit: contain;
  display: block;
}

.rwcbanner {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.rwcbanner p {
  top: 3em;
  position: absolute;
  background-color: #ffffff;
  font-size: 3.3em;
  color: #000000;
  font-family: Georgia, "Times New Roman", Times, serif;
  text-align: center;
}

button {
  padding: 8px 16px;
  border: none; /* Optional: remove border */
  background-color: transparent; /* Optional: transparent background */
  cursor: pointer; /* Optional: change cursor on hover */
}

button:hover {
  background-color: #ddd;
  color: black;
}

.arrow-container {
  display: inline-block;
  width: 70px; /* Diameter of the circle */
  height: 70px; /* Diameter of the circle */
  background-color: #b1aeae;
  border-radius: 50%;
  position: absolute;
  top: 20%;
  left: 5%;
}

.arrow {
  position: absolute;
  top: 35%;
  left: 40%;
  width: 20px; /* Adjust size */
  height: 20px; /* Adjust size */
  border-style: solid;
  border-color: transparent transparent rgb(255, 255, 255) rgb(255, 255, 255);
  border-width: 0 0px 4px 4px;
  transform: translate(-50%, -50%) rotate(45deg); /* Adjust positioning and rotation */
  transition: transform 0.3s, border-color 0.3s;
}

.arrow:hover {
  border-color: transparent transparent rgb(243, 192, 141) rgb(243, 192, 141);
}

.arrow-left {
  transform: rotate(45deg);
}
h1.rwc {
  text-align: center;
  font-size: 2.5em;
}
h2.rwc {
  margin-top: 1em;
  font-size: 2em;
  text-align: center;
}

.rwcsitemap-container {
  display: flex;
  justify-content: center;
}

.rwcsitemap-container img {
  width: 80%;
}

h3.rwc {
  font-weight: lighter;
  font-family: "Courier New";
  margin-left: 15em;
  margin-right: 15em;
  font-size: 1em;
  line-height: 1.5em;
}

h4.rwc {
  font-weight: lighter;
  font-family: "Courier New";
  margin-right: 6em;
  margin-left: 8em;
  font-size: 1em;
  margin-top: 2em;
  line-height: 1.5em;
}

h5.rwc {
  top: 3em;
  position: absolute;
  background-color: #000000;
  font-size: 3em;
  color: #ffffff;
  font-family: Georgia, "Times New Roman", Times, serif;
}

.rwcmockup1 img {
  width: 40%;
  align-items: center;
}

.rwcmockup1 {
  display: flex;
  justify-content: center;
}

.wireframe-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-items: center; /* Center items along the row */
  align-items: center; /* Center items along the column */
  padding-left: 20%;
  width: 60%;
  gap: 1em;
  /* margin-top: 2em; */
}

.wireframe-grid img {
  width: 90%;
}

.rwcmockup-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  justify-items: center; /* Center items along the row */
  align-items: center; /* Center items along the column */
  max-width: 100%;
  margin-left: 15em;
  margin-right: 15em;
}

.rwcmockup-grid img {
  max-width: 100%;
  width: 90%;
  align-items: center;
  margin-top: 1em;
}

.mockup-text p {
  text-align: left;
  font-size: 2em;
  margin-bottom: 0;
  font-weight: bold;
  font-family: Arial, Helvetica, sans-serif;
  margin-left: 7em;
  color: rgb(87, 169, 169);
}
