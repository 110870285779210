body {
  background-color: white;
}
.avalonbanner img {
  width: 100%;
  filter: blur(2px);
  object-fit: contain;
  display: block;
}

.avalonbanner {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.avalonbanner p {
  top: 3em;
  position: absolute;
  background-color: #ffffff;
  font-size: 3.5em;
  color: #000000;
  font-family: Georgia, "Times New Roman", Times, serif;
}

.intro-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-top: 3em;
  margin-left: 1em;
}

h1.avalon {
  text-align: center;
  font-size: 2.5em;
  font-family: Georgia, "Times New Roman", Times, serif;
}

.problempic img {
  width: 65%;
  margin: 2em;
  justify-content: center;
  object-fit: contain;
  display: block;
}

h2.avalon {
  margin-top: 2em;
  font-size: 2em;
  text-align: center;
  font-family: Georgia, "Times New Roman", Times, serif;
}

.avalonsitemap-container {
  display: flex;
  justify-content: center;
  margin-right: 15em;
}

h3.avalon {
  font-weight: lighter;
  font-family: "Courier New";
  margin-left: 20em;
  margin-right: 20em;
  font-size: 1em;
  line-height: 1.5em;
}

h4.avalon {
  font-weight: lighter;
  font-family: "Courier New";
  margin-right: 6em;
  margin-left: 8em;
  font-size: 1em;
  margin-top: 2em;
  line-height: 1.5em;
}

.a-mockup {
  display: grid;
  grid-template-columns: 5fr 5fr;
  margin-top: 3em;
  margin-left: 25%;
  margin-right: 25%;
}

.a-mockup img {
  width: 95%;
  filter: contrast(50%);
}

ul.avalonlist {
  margin-left: 50%;
  font-family: "Courier New", Courier, monospace;
  line-height: 2em;
  margin-top: 3em;
}

.togglegrid {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.togglepic {
  width: 30%;
  height: 85%;
  margin-top: 2em;
}

h3.phasetitle {
  font-weight: bold;
  margin-left: 21%;
  font-size: 110%;
  text-decoration: underline;
  font-family: Georgia, "Times New Roman", Times, serif;
}

.mockuppic img {
  width: 50%;
  align-self: center;
}

.mockuppic {
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
}

.avalonlink {
  margin-left: 17%;
  text-decoration: underline;
  font-size: 1.2em;
  color: #e37e2a;
}
