#projects {
  background: rgb(227, 255, 216);
  padding-bottom: 80px;
  height: auto;
}

.project-headers {
  grid-column: 1 / -1;
  z-index: 1000;
  text-align: center;
  font-size: 3.5em;
  font-family: "syncopate", sans-serif;
  color: rgb(44, 174, 53);
  margin: 2.5rem 0.5rem 0 0.5rem;
}

a {
  text-decoration: none;
}

.overlay p {
  text-align: center;
  position: absolute;
  top: 0.5vw;
  color: rgb(113, 220, 120);
  font-size: 1.2vw;
  display: none;
  margin: 3.2vw;
  width: calc(100% - 20%); /* Adjust width to allow padding */
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.overlay:hover img {
  filter: brightness(0.4);
  -webkit-filter: brightness(0.1);
}

.overlay:hover p {
  display: block;
}

.container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 55%);
  justify-items: center;
  gap: 10px;
  row-gap: 3%;
  margin-top: 4.5em;
}

.gallery {
  width: 92%;
  position: relative;
  overflow: hidden;
}

.roletitle p {
  margin-top: 0.4em;
  text-align: left;
  color: rgb(44, 174, 53);
  font-size: 1em;
  margin-bottom: 1%;
  font-family: "Courier New", Courier, monospace;
}
.gallery__img {
  width: 100%;
  object-fit: contain;
  align-items: center;
  justify-content: center;
  transition: transform 0.5s ease;
}
.overlay:hover .bn30 {
  display: block; /* Shows the button when the overlay is hovered */
}
.bn30 {
  border: 1em;
  cursor: pointer;
  outline: none;
  font-size: 16px;
  -webkit-transform: translate(0);
  transform: translate(0);
  background-image: linear-gradient(45deg, #75e3b9, #298b62);
  padding: 0.7em 2em;
  border-radius: 65px;
  box-shadow: 1px 1px 10px rgba(164, 163, 163, 0.438);
  -webkit-transition: box-shadow 0.25s;
  transition: all 0.25s ease-in-out;
  color: white;
  text-align: center;
  position: absolute;
  transform: translate(-50%, -50%);
  display: none;
  top: 13vw; /* Centers the button vertically */
  left: 50%;
}
.bn30 .text {
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-image: linear-gradient(45deg, #949fc2, #b06ab3);
  text-align: center;
  position: absolute;
  font-family: Georgia, "Times New Roman", Times, serif;
}

.bn30:after {
  border-radius: 18px;
  position: absolute;
  margin: 4px;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: -1;
  background: #727296;
}

.bn30:hover {
  background-image: linear-gradient(-45deg, #45dc81, #467b58);
  box-shadow: 0 12px 24px rgba(128, 128, 128, 0.1);
}

.bn30:hover .text {
  background-image: linear-gradient(-45deg, #32a053, #23724d);
  display: block;
}

@media screen and (max-width: 1250px) {
  .gallery {
    width: 100%;
    grid-template-columns: repeat(32, 3%);
    grid-template-rows: repeat(15, 6%);
  }

  .roletitle p {
    margin-top: 2%;
    text-align: center;
    color: rgb(44, 174, 53);
    font-size: 15px;
    margin-left: 0;
    margin-right: 0;
  }
}

@media screen and (max-width: 800px) {
  #projects {
    flex-direction: column; /* Ensures content is laid out top-to-bottom */
    align-items: center; /* Centers content horizontally */
    padding: 1em; /* Add padding to ensure content does not touch the edges */
    margin: auto; /* Center the resume section if needed */
    width: 100%;
    justify-content: flex-start;
    height: auto;
    padding-bottom: 0;
  }

  .project-headers {
    margin-top: 1em;
    text-align: center;
  }
  .gallery {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    align-items: center;
    padding: 2em;
  }

  .overlay p {
    font-size: 4vw;
    top: 10vh;
    width: calc(100% - 20%);
  }

  .container {
    display: grid;
    grid-template-columns: 2fr;
    grid-template-rows: repeat(6, 15%);
    width: 100%; /* Ensure container is full width */
    align-items: center;
    row-gap: 1em;
    justify-content: center;
  }
  .gallery__img {
    width: 100%; /* Ensures image takes the full width */
    height: auto; /* Adjust height automatically */
    object-fit: contain;
    align-items: center;
  }

  .bn30 {
    top: 48vw;
    font-size: 10px;
  }
}
