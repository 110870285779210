#contact {
  background-image: url("../../public/res/contact-phone.jpg");
  filter: contrast(20%), brightness(45%);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 100vh;
  display: block;
  padding: 8% 15%;
  box-sizing: border-box;
  width: 100%;
  background-attachment: scroll;
  margin: 0;
}

.contact-headers {
  z-index: 1000;
  margin-top: -0.2em;
  position: sticky;
  font-size: 400%;
  font-family: "syncopate", sans-serif;
  color: rgb(70, 137, 213);
  font-weight: lighter;
  margin-left: 0;
}

.contact-text {
  padding: 0 4%;
  color: rgb(70, 137, 213);
  font-size: 120%;
  line-height: 0.5em;
  font-weight: lighter;
  font-family: "Courier New", Courier, monospace;
  margin-left: -0.5em;
  margin-bottom: -1em;
}

.url-color {
  color: rgb(70, 137, 213);
  text-decoration: underline;
}

.contact-icon-item {
  display: flex;
  margin-left: 10%;
  align-items: center; /* Center-align the content of each grid item */
  /* Ensure text is centered under icons */

  /* Adds space between rows */
}

.contact-icon-item :first-of-type {
  margin-top: 1.5em;
}

.contact-icons img {
  width: 2.1rem;
  height: 2.1rem;
  filter: invert(100%);
}

.contact-icons-phone img {
  width: 2.5rem;
  height: 2.5rem;
  filter: invert(100%);
}

.contact-icons-linkedin img {
  width: 2.2rem;
  height: 2.2rem;
}

.contact-icons-github img {
  width: 2.1rem;
  height: 2.1rem;
}

@media (max-width: 964px) {
  #contact {
    flex-direction: column; /* Maintain two-column layout */
    padding: 15% 15%;
  }

  .contact-headers {
    margin-left: 0;
    font-size: 300%;
    margin-top: 1em;
  }
}

@media (max-width: 500px) {
  #contact {
    height: 100vh;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 10% 20%;
  }

  .contact-headers {
    font-size: 2em; /* Smaller font size for mobile */
    margin-top: 1em;
  }

  .contact-text {
    font-size: 100%; /* Smaller font size for text */
    color: rgb(123, 95, 76);
    margin: 10%;
  }

  .contact-icon-item {
    display: flex;
    flex-direction: column; /* Stack icons on top of text */
    /* Center-align items horizontaslly in the flex container */
    justify-content: center; /* Center-align items vertically in the flex container */
    text-align: center; /* Ensure text is centered under icons */
    align-items: center;
    margin-left: 5%;
    margin-top: 5%;
  }
}
