#resume {
  background: rgb(255, 233, 233);
  height: auto;
  display: flex;
  flex-direction: column;
}

.resume-headers {
  z-index: 1000;
  position: flex;
  text-align: center;
  font-size: 2.5em;
  font-family: "syncopate", sans-serif;
  color: rgb(126, 98, 78);
  font-weight: normal;

  margin: 2rem 0 0 0;
}

.open-resume {
  width: 0.8em;
  margin-left: 0;
  color: rgb(126, 98, 78);
}

.grid-container {
  display: grid;
  grid-template-columns: 2fr 3fr;
  gap: 4vh;
  justify-items: center;
  align-content: center;
  margin: 2rem;
}

.grid-container .resumeCol {
  justify-items: center;
  align-content: center;
}

.grid-skills {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-top: 0;
}

.skill-list {
  margin-top: 0;
}

.resume-title {
  font-size: 26px;
  font-weight: 700;
  margin-top: 0.5em;
  color: rgb(153, 112, 83);
  font-family: Georgia, "Times New Roman", Times, serif;
}

h3 {
  margin-bottom: 0.5em;
}

.resume-item {
  padding: 0 0 10px 15px;
  position: relative;
  color: rgb(153, 112, 83);
  border-left: 2px solid #9d7c68;
  justify-items: left;
  align-content: left;
  font-family: Georgia, "Times New Roman", Times, serif;
  font-size: 14px;

  h6 {
    font-size: 17px;
    font-weight: 600;
    text-transform: uppercase;
    margin-bottom: 0;
    position: relative;
    margin-top: 0;
    font-family: Georgia, "Times New Roman", Times, serif;
  }

  h5 {
    font-size: 14px;
    background-color: rgb(202, 196, 196);
    padding: 0px 10px;
    font-weight: 600;
    margin-top: 0.8em;
    margin-bottom: 0;
    margin-left: 0.5em;
    max-width: fit-content;
    font-family: Georgia, "Times New Roman", Times, serif;
  }

  ul {
    padding-left: 12px;
    padding-top: 0;

    li {
      padding-bottom: 5px;
    }
  }

  &:last-child {
    padding-bottom: 0;
  }
}

.h11 {
  color: rgb(153, 112, 83);
  font-family: Georgia, "Times New Roman", Times, serif;
}

.resume-item h6::before {
  content: ""; /* Required for the pseudo-element to work */
  position: absolute; /* Position absolutely within the h6 */
  left: -1.2em; /* Adjust this value so it aligns with the vertical border */
  top: 20%; /* Align the circle's center with the center of the h6 */
  transform: translateY(-50%); /* This centers the circle vertically */
  width: 12px; /* Circle size */
  height: 12px; /* Circle size */
  background-color: #9d7c68; /* Circle color - adjust as needed */
  border-radius: 50%; /* This makes the square a circle */
}

.resume-item .h15::before {
  content: ""; /* Required for the pseudo-element to work */
  position: absolute; /* Position absolutely within the h6 */
  left: -0.4em; /* Adjust this value so it aligns with the vertical border */
  top: 3%; /* Align the circle's center with the center of the h6 */
  transform: translateY(-50%); /* This centers the circle vertically */
  width: 12px; /* Circle size */
  height: 12px; /* Circle size */
  background-color: #9d7c68; /* Circle color - adjust as needed */
  border-radius: 50%; /* This makes the square a circle */
}

@media (max-width: 568px) {
  /* Adjust the max-width as needed */
  #resume {
    flex-direction: column; /* Ensures content is laid out top-to-bottom */
    align-items: center; /* Centers content horizontally */
    padding: 1em; /* Add padding to ensure content does not touch the edges */
    margin: auto; /* Center the resume section if needed */
    width: 100%; /* Ensure it spans the full width */
    min-height: auto; /* Minimum height to at least cover the viewport */
    height: auto;
  }

  .resume-item h6::before {
    content: ""; /* Required for the pseudo-element to work */
    position: absolute; /* Position absolutely within the h6 */
    transform: translateY(-50%); /* This centers the circle vertically */
    width: 12px; /* Circle size */
    height: 12px; /* Circle size */
    background-color: #9d7c68;
    border-radius: 50%; /* This makes the square a circle */
  }

  .grid-container {
    grid-template-columns: 1fr;
  }
}
