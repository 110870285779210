html {
  scroll-behavior: smooth;
  margin: 0;
  padding: 0;
}
body {
  margin: 0;
  padding: 0;
  /* height: 100vh; */
  width: 100vw;
  scroll-behavior: smooth;
  position: relative;

  background-color: rgb(144, 175, 175);
}

html,
body {
  overflow-x: hidden;
}

.home-body {
  height: 100vh;
  width: 100vw;
  background-image: linear-gradient(rgba(0, 0, 0, 0.685), rgba(0, 0, 0, 0.616)),
    url("../../public/res/homepic.jpg"); /* Path to your image */
  background-size: cover; /*Cover the entire div area*/
  background-position: center;
  background-attachment: fixed;
  position: relative; /* Allows for absolute positioning inside */
  margin: 0; /* Ensure no default margins are applied */
  padding: 0;
}

/* Ensure that sections take at least the height of the viewport, with padding adjustments for smaller screens */
section {
  height: 100vh;
  padding: 50px 10px;
  /* Responsive padding */
}

h1 {
  font-size: 10vw;
}

.hey-im-heading {
  color: #d8d1d1; /* Change text color */
  text-align: center; /* Center align the text */
  font-size: 6em; /* Increase font size */
  font-weight: bold; /* Make the font weight bold */
  margin-top: 20px; /* Add margin on top */
  margin-bottom: 10px; /* Add margin on the bottom */
  position: absolute;
  top: 60%;
  left: 50%;
  margin: 0;
  transform: translate(-50%, -50%);
  font-family: Orbitron;
}

.name-heading {
  color: #d8d1d1; /* Change text color */
  font-family: Orbitron; /* Change font family */
  text-align: center; /* Center align the text */
  font-size: 3em; /* Increase font size */
  position: absolute;
  top: 75%;
  left: 50%;
  margin: 0;
  font-weight: lighter;
  transform: translate(-50%, -50%);
  display: inline-block;
  border-right: 4px solid white; /* The cursor */
  animation: typing 4s steps(40, end), blink-caret 0.75s step-end infinite;
}

.typing-container {
  width: fit-content;
  border-right: 2px solid; /* Simulate the cursor */
  white-space: nowrap; /* Ensure text stays in a single line */
  overflow: hidden; /* Hide the overflow content */
  animation: typing 8s steps(12, end), blink-caret 0.75s step-end infinite; /* Add typing and blinking animation */
  margin: 0 auto; /* Center the container */
}

/* Typing animation */
@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 16%;
  }
}

/* Cursor blink animation */
@keyframes blink-caret {
  from,
  to {
    border-color: transparent;
  }
  20% {
    border-color: rgba(255, 255, 255, 0.532);
  }
}

.button-style {
  padding: 1vw 2vw;
  color: rgb(255, 249, 249);
  font-family: "Courier New", Courier, monospace;
  text-decoration: none;
  align-content: end;
  font-size: 1em;
}

.button-style:hover {
  text-decoration: underline;
}

.buttons-container {
  text-align: right; /* Aligns the container's content to the right */
  margin-right: 5rem; /* Optional: Adds some space to the right side */
  position: fixed;
  width: 100%;
  max-height: 1rem;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  padding: 1.5rem 0;
  display: inline-block;
}

.initials-container {
  position: fixed; /* Fix the position relative to the viewport */
  top: 14px; /* Distance from the bottom */
  left: 20px; /* Distance from the right */
  width: 40px; /* Width of the square */
  height: 38px; /* Height of the square */
  color: white; /* Color of the initials */
  text-align: center; /* Center the initials horizontally */
  line-height: 40px; /* Center the initials vertically */
  font-weight: bold; /* Make the initials bold */
  font-size: 1.5em; /* Size of the initials */
  z-index: 10000;
  display: inline-block;
  border: 2px solid #ffffff;
  font-family: "Architects Daughter", cursive; /* Change font family */
}

.scroll-down {
  opacity: 1;
  -webkit-transition: all 0.5s ease-in 3s;
  transition: all 0.5s ease-in 3s;
}

.scroll-down {
  position: absolute;
  bottom: 60px;
  left: 50%;
  margin-left: -16px;
  display: block;
  width: 40px;
  height: 40px;
  border: 2px solid #fff;
  background-size: 14px auto;
  border-radius: 50%;
  z-index: 2;
  -webkit-animation: bounce 2s infinite 2s;
  animation: bounce 2s infinite 2s;
  -webkit-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in;
}

.scroll-down:before {
  position: absolute;
  top: calc(50% - 8px);
  left: calc(50% - 6px);
  transform: rotate(-45deg);
  display: block;
  width: 12px;
  height: 12px;
  content: "";
  border: 2px solid white;
  border-width: 0px 0 2px 2px;
}
@keyframes bounce {
  0%,
  100%,
  20%,
  50%,
  80% {
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
  40% {
    -webkit-transform: translateY(-10px);
    -ms-transform: translateY(-10px);
    transform: translateY(-10px);
  }
  60% {
    -webkit-transform: translateY(-5px);
    -ms-transform: translateY(-5px);
    transform: translateY(-5px);
  }
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #d4cdcd;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #1a181849;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #1f1f1f;
}

@media (max-width: 500px) {
  .buttons-container {
    position: absolute;
    top: 0;
    right: 0;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-start;
    padding: 1em 0;
    text-align: right;
    background: none;
  }

  .button-style {
    padding: 1rem 1.5rem;
    margin: 3px 2px; /* Space between buttons */
    display: block;
    text-align: right;
    background-color: transparent;
    border: none;
    position: static;
  }
  .home-body {
    background-attachment: scroll; /* Prevent fixed background on mobile */
    padding: 20px;
  }

  .name-heading {
    font-size: 3.5em; /* Adjusted font size for smaller screens */
    text-align: center;
    position: absolute; /* Remove absolute positioning */
    margin: 0; /* Add some margin */
  }

  .typing-container {
    text-align: center;
    margin-top: 15px;
  }

  .initials-container {
    top: 5px; /* Adjust position closer to top */
    left: 5px; /* Adjust position closer to left */
    width: 30px; /* Smaller width */
    height: 30px; /* Smaller height */
    font-size: 1em; /* Smaller font size */
    position: absolute;
    /* Ensure these elements don't exceed screen width */
    box-sizing: border-box;
  }

  .home-body {
    padding: 20px; /* Adjust padding for mobile */
    overflow-x: hidden;
    box-sizing: border-box;
  }

  body {
    overflow-x: hidden;
    padding: 0;
    box-sizing: border-box;
    margin: 0;
  }

  section {
    padding: 15px; /* Ensure these elements don't exceed screen width */
    /* max-height: 100vh; */
    overflow-x: hidden;
    box-sizing: border-box;
  }

  .scroll-down {
    bottom: 4em;
  }
}

@media (max-width: 500px) {
  .hey-im-heading {
    margin-left: 0;
    margin-right: 0;
    font-size: 2em;
    text-align: center;
    position: absolute; /* Remove absolute positioning */
    margin: 0;
  }
}
